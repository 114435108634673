import Vue from "vue";
import VueRouter from "vue-router";
import { getToken } from '../utils/auth'

//防止因重复跳转页面引起的报错
import Router from 'vue-router';
const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		name:'/home',
		component: () => import('@/views/Main.vue')

	},
	{
		path: '/video',
		name:'/video',
		component: () => import('@/views/video/index.vue')
	},
	{
		path: '/answer',
		name:'/answer',
		component: () => import('@/views/answer/index.vue')
	},
	{
		path: '/record',
		name:'/record',
		component: () => import('@/views/record/index.vue')
	},
	{
		path: '/examinRecord',
		name:'/examinRecord',
		component: () => import('@/views/examinRecord/index.vue')
	},
	{
		path: '/reserveExamine',
		name:'/reserveExamine',
		component: () => import('@/views/answer/reserveExamine.vue')
	},
	// {
	// 	path: '/test',
	// 	name:'/test',
	// 	component: () => import('@/views/test/index.vue')
	// },

	// {
	// 	path: '/login',
	// 	name: 'login',
	// 	component: () => import('@/views/Login/Login')
	// },
	// {
	// 	path: '*',
	// 	name: 'login',
	// 	component: () => import('@/views/Login/Login')
	// },

];

const router = new VueRouter({
	mode: 'history',
	routes,
});


//路由守卫  to,from,next
// router.beforeEach((to, from, next) => {
// 	let token = getToken()
// 	if (!token && to.name !== 'login') {
// 		next({ name: 'login' })
// 	} else {
// 		next()
// 	}
// })



export default router;
